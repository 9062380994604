import * as types from "./actionTypes";

export function user_signin(payload) {
  return {
    type: types.USER_SIGNIN,
    payload: payload,
  };
}
export function user_google_signin(payload) {
  return {
    type: types.USER_GOOGLE_SIGNIN,
    payload: payload,
  };
}
export function user_google_verify(payload) {
  return {
    type: types.USER_GOOGLE_VERIFICATION,
    payload: payload,
  };
}
export function user_notification(payload) {
  return {
    type: types.USER_NOTIFICATION,
    payload: payload,
  };
}
export function get_notification_count_action(payload) {
  return {
    type: types.NOTIFICATION_COUNT,
    payload: payload,
  };
}
export function get_sidebar_action(payload) {
  return {
    type: types.SIDEBAR_DATA,
    payload: payload,
  };
}


export function get_all_employee(payload) {
  
  return {
    type: types.GET_ALL_EMPLOYEE_SUCCESS,
    payload: payload,
  };
}

export function get_all_manager(payload) {
  return {
    type: types.GET_ALL_MANAGER_SUCCESS,
    payload: payload,
  };

}
export function get_all_user(payload) {
  return {
    type: types.GET_ALL_USER_SUCCESS,
    payload: payload,
  };

}

export function uploadfile(payload) {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
    payload: payload,
  };
}
export function forgot_password(payload) {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload: payload,
  };
}
export function otp_varification(payload) {
  return {
    type: types.OTP_VARIFICATION_SUCCESS,
    payload: payload,
  };
}
export function reset_password(payload) {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: payload,
  };
}
export function update_profile(payload) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    payload: payload,
  };
}


export function getnotificationlist(payload) {
  return {
    type: types.NOTIFICATION_LIST_SUCCESS,
    payload: payload,
  };
}






