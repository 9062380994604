/* development */
// export const wrapupApi = "http://localhost:3500";
/* Deployed*/
export const wrapupApi =  "https://api.wrapup.live";
export const wrapupAIApi = "https://ai.wrapup.live"
export const onboardingApp = 'https://onboarding.wrapup.live'
export const landingApp = 'https://wrapup.live'
export const todoApp = 'https://todo.wrapup.live'

export const wrapupmain = "https://task.wrapup.live/"

//Access Details
export const Admin = "Admin"
export const Manager = "Manager"
export const User = "User"
export const Project = "Project"
export const Task = "Task"
export const Project_Read = "Project Read"
export const Task_Read = "Task Read"
export const Project_Full = "Project Both"
export const Task_Full = "Task Both"





