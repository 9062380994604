import * as types from "./actionTypes";

let userobj = {
  loaded: false,
  loading: false,
  profile: {
    email: "",
    firstname: "",
    profileimage: "",
    lastname: "",
    token: "",
    firebasetoken: "",
    data: {},
    employeeid: "",
  },
  managers: [],
  employees: [],
  users: [],
  notificationcount: 0,
  notifications: [],
  sidebar: []

};



export default function accountReducer(state = userobj, { type, payload }) {
  switch (type) {
    case types.USER_SIGNIN:
      userobj.profile.email = payload.email;
      userobj.profile.firstname = payload.firstname;
      userobj.profile.profileimage = payload.profileimage;
      userobj.profile.lastname = payload.lastname;
      userobj.profile.firebasetoken = payload.firebasetoken;
      userobj.profile.token = payload.token;
      userobj.profile.employeeid = payload.employeeid;

      return {
        ...state,
        loaded: true,
        loading: false,
        profile: userobj.profile,
      };
    case types.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationcount: payload,
      };

    case types.USER_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
      };
    case types.SIDEBAR_DATA:
      return {
        ...state,
        sidebar: payload,
      };

    case types.USER_GOOGLE_SIGNIN:
      userobj.profile.email = payload.email;
      userobj.profile.firstname = payload.firstname;
      userobj.profile.profileimage = payload.profileimage;
      userobj.profile.lastname = payload.lastname;
      // userobj.profile.role = payload.role;
      userobj.profile.token = payload.token;
      userobj.profile.employeeid = payload.employeeid;

      return {
        ...state,
        loaded: true,
        loading: false,
        profile: userobj.profile,
      };

    case types.GET_ALL_MANAGER_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        managers: payload,
      }

    case types.GET_ALL_EMPLOYEE_SUCCESS:

      return {
        ...state,
        loaded: true,
        loading: false,
        employees: payload,
      }
    case types.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        users: payload,
      }

    default:
      return state;
  }
}
