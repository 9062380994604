
import axios from "axios"
import { wrapupApi, wrapupAIApi } from '../../config';
import {
  get_project_list_success, projectwiseuserlist,
  add_project_success,
  get_single_project_details_success,
  getjiraprojectwiseissue,
  getepiclist,
  getOrganizationUsersSuccess

} from "./projectActions";
import { add_task_success } from "../task/taskActions"

export const get_project_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/project/get/paginate/projects`, payload, config)
      .then(res => {

        const value = res.data

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const create_project =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };

    axios.post(`${wrapupApi}/api/project/add-project`, payload, config)
      .then(res => {

        // const value = res.data
        // dispatch(add_project_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        // callback({ success: false,data: err  });
      })
  };

export const update_project =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };

    let resp = axios.post(`${wrapupApi}/api/project/update-project`, payload, config)
      .then(res => {

        // const value = res.data
        // dispatch(update_project_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        // callback({ success: false });
      })
    return resp;
  };


export const get_project_details =
  (projectid, callback) => async (dispatch) => {

    axios.post(`${wrapupApi}/api/project/get/projectdetails`, projectid)
      .then(res => {
        dispatch(get_single_project_details_success(res.data))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

export const get_search_project_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/project/get/paginate/searchprojectlist`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };


export const get_issue_list_for_jira_project =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getepics`, payload, config)
      .then(async res => {
        const value = res.data
        dispatch(getjiraprojectwiseissue(value))
        let payloaddata = {
          projectKey: payload.projectKey
        }
        dispatch(get_user_list_for_jira_project_wise(Token, payloaddata))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_epic_list_for_jira_project =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getprojectwiseepics`, payload, config)
      .then(async res => {
        const value = res.data
        // Initialize an empty array to store the related epics summary
        const relatedEpicsArray = [];
        // Iterate over each object in the data array
        await value.forEach((item) => {
          const newdata = {}
          newdata.relatedEpic = item.summary; // Get the first related epic (if it exists)
          newdata.key = item.key

          relatedEpicsArray.push(newdata);

        });
        // Use a Set to store unique values
        const uniqueValuesEpics = new Set(relatedEpicsArray);
        // Convert the Set back to an array (if needed)
        const epicsWithoutDuplicates = Array.from(uniqueValuesEpics);
        dispatch(getepiclist(epicsWithoutDuplicates))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_user_list_for_jira_project_wise =
  (Token, payload) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getprojectwiseusers`, payload, config)
      .then(res => {
        const value = res.data
        dispatch(projectwiseuserlist(value))
        // callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const add_comment_for_issue =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/addcomment`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_organization_wise_users =
  (Token, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getorganizationusers`, {}, config)
      .then(res => {
        const value = res.data
        dispatch(getOrganizationUsersSuccess(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const getSprintChartData =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getsprintchartdata`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const update_status_for_issue =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/updatestatus`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const uploadfileonJira =
  (Token, formData, Projectkey, TaskKey, email, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    await axios.post(`${wrapupApi}/api/jira/addattachment/${Projectkey}/${email}/${TaskKey}`, formData, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

  export const getfilefroms3 = (Token, key, callback) => async (dispatch) => {
    const config = {
        headers: { Authorization: `${Token}` }
    };
    try {
        const response = await axios.get(`${wrapupApi}/api/jira/get/files?key=${key}`, config);
        callback({ success: true, data: response.data });
    } catch (error) {
        console.error('Error fetching presigned URL:', error);
        callback({ success: false, error });
    }
};


export const assignprojectaccess =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/assignproject`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const assignissue =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/assignissue`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const referenceAndEstimate =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/update/referenceandestimate`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };


export const getJiraCredential =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/jira/getjiracredential`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: value });
      })
      .catch(err => {
        console.log(err);
      })
  };


export const getResourceForIssue =
  (payload, callback) => async (dispatch) => {
    axios.post(`${wrapupAIApi}/resgenerator`, payload)
      .then(res => {
        const value = res.data
        if (callback) {
          callback({ success: true, data: value });
        }
      })
      .catch(err => {
        if (callback) {
          callback({ success: false, data: err });
        }
      })
  };

export const getEstimateTimeForIssue =
  (payload, callback) => async (dispatch) => {
    axios.post(`${wrapupAIApi}/estimate`, payload)
      .then(res => {
        const value = res.data
        if (callback) {
          callback({ success: true, data: value });
        }
      })
      .catch(err => {
        if (callback) {
          callback({ success: false, data: err });
        }
      })
  };

export const detectEmotionForComment =
  (payload, callback) => async (dispatch) => {
    axios.post(`${wrapupAIApi}/emotion`, payload)
      .then(res => {
        const value = res.data
        if (callback) {
          callback({ success: true, data: value });
        }
      })
      .catch(err => {
        if (callback) {
          callback({ success: false, data: err });
        }
      })
  };