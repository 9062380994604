import axios from "axios"
import { wrapupApi } from '../../config';
import {
  user_signin,
  user_google_signin,
  get_all_employee,
  get_all_manager,
  forgot_password,
  otp_varification,
  reset_password,
  get_sidebar_action,
  get_notification_count_action,
  user_notification,
  user_google_verify,
  get_all_user,
  user_provider_verification

} from "./accountActions";


export const wrapup_users_register =
  (payload, callback) => async (dispatch) => {

    axios.post(`${wrapupApi}/api/users/register-user`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);      })
  };


export const wrapup_users_login =
  (payload, callback) => async (dispatch) => {
    axios.post(`${wrapupApi}/api/users/login-users`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        callback({ success: false, data: err });      })
  };

  export const wrapup_google_login =
  (payload, callback) => async (dispatch) => {
    axios.post(`${wrapupApi}/api/users/google-login-users`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_google_signin(value))
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        callback({ success: false, data: err });      })
  };

  export const google_verification_user =
  (payload, callback) => async (dispatch) => {

    axios.post(`${wrapupApi}/api/users/google-verify-users`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_google_verify(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }




export const get_user_profile =
  (Token, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.get(`${wrapupApi}/api/users/profile`, config)
      .then(res => {
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_employees =
  (Token, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.get(`${wrapupApi}/api/users/getemployee`, config)
      .then(res => {

        const value = res.data
        dispatch(get_all_employee(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_managers =
  (Token, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.get(`${wrapupApi}/api/users/getmanager`, config)
      .then(res => {
        

        const value = res.data
        dispatch(get_all_manager(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
  export const get_users =
  (Token, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.get(`${wrapupApi}/api/users/getusers`, config)
      .then(res => {

        const value = res.data
        dispatch(get_all_user(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
export const uploadfile =
  (formData, callback) => async (dispatch) => {
    await axios.post(`${wrapupApi}/api/upload-file`, formData)
      .then(res => {

        // const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
export const get_user_by_role =
  (grouptype, callback) => async (dispatch) => {

    axios.get(`${wrapupApi}/api/users/getemployee`, grouptype)
      .then(res => {

        const value = res.data
        if (grouptype.grouptype == "Manager") {
          dispatch(get_all_manager(value))
        }
        else if (grouptype.grouptype == "Employee") {
          dispatch(get_all_employee(value))
        }
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_user =
  (role, callback) => async (dispatch) => {

    axios.get(`${wrapupApi}/api/users/getmanager`, role)
      .then(res => {
        const value = res.data
        if (role.role == "manager") {
          dispatch(get_all_manager(value))
        }
        else if (role.role == "employee") {
          dispatch(get_all_employee(value))
        }
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

export const get_project_details =
  (projectid, callback) => async (dispatch) => {

    axios.get(`${wrapupApi}/api/project/get/projectdetails`, projectid)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
export const wrapup_users_forgotpassword =
  (payload, callback) => async (dispatch) => {

    axios.patch(`${wrapupApi}/api/users/forgot-password`, payload)
      .then(res => {
        const value = res.data
        dispatch(forgot_password(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  export const wrapup_users_otpvarification =
  (payload, callback) => async (dispatch) => {

    axios.post(`${wrapupApi}/api/users/otp-verification`, payload)
      .then(res => {
        const value = res.data
        dispatch(otp_varification(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  export const wrapup_users_resetpassword =
  (payload, callback) => async (dispatch) => {

    axios.post(`${wrapupApi}/api/users/reset-password`, payload)
      .then(res => {
        const value = res.data
        dispatch(reset_password(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  export const get_users_notification_count =
  (Token, data, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/notification/get/notification/count`, data, config)
      .then(res => {
        const value = res.data
        dispatch(get_notification_count_action(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  
  export const users_notification =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/notification/get/notification`, payload, config )
      .then(res => {
        const value = res.data
        dispatch(user_notification(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  export const users_notification_seen =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/notification/get/notification/seen`, payload, config )
      .then(res => {
        // const value = res.data
        // dispatch(user_notification(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  export const wrapup_users_updateprofile =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    
    axios.post(`${wrapupApi}/api/users/user-update-profile`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

  export const wrapup_users_provider_checking =
  (payload, callback) => async (dispatch) => {
    
    axios.post(`${wrapupApi}/api/users/user-email-provider`, payload)
      .then(res => {
        // const value = res.data
        // dispatch(user_provider_verification(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

  export const update_event =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/notification/updateevent`, payload, config )
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }



export const fetch_sidebar_data =
  (Token, data, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/sidebar/user-sidebar`, data, config)
      .then(res => {
        const value = res.data
        dispatch(get_sidebar_action(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
  